import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import reducers from "./RootReducer";
import rootSaga from "./RootSaga";
const rootReducers = (state, action) => {
  if (action.type === "LOGOUT_REQUEST_SUCCESS") {
    state = undefined;
  }
  return reducers(state, action);
};

const sagaMiddleware = createSagaMiddleware();

export default configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
