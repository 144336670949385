import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react";
import AuthenticatedRoutes from "../routes/AuthenticatedRoutes";
import DefaultLayout from "../layout/DefaultLayout";
// routes config
import routes from "../routes/routes";

const getRoutes = (allRoutes) => {
  return allRoutes.map((route, idx) => {
    if (route.element) {
      return (
        <React.Fragment key={idx}>
          {!route.authenticated && (
            <>
              <Route
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={<route.element />}
              />
            </>
          )}
          {
            <React.Fragment>
              <Route path="/" element={<AuthenticatedRoutes />}>
                {/* <CContainer lg> */}
                <Route
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={
                    <DefaultLayout>
                      <CContainer lg>
                        <route.element />
                      </CContainer>
                    </DefaultLayout>
                  }
                />
                {/* </CContainer> */}
              </Route>
            </React.Fragment>
          }
        </React.Fragment>
      );
    }
    return null;
  });
};

const AppContent = () => {
  return (
    <Suspense fallback={<CSpinner color="primary" />}>
      <Routes>{getRoutes(routes)}</Routes>
    </Suspense>
  );
};

export default AppContent;
