/* const initialState = {
    sidebarShow: true,
    sidebarUnfoldable: true,
};

export default function sideBarShowReducer(state = initialState, { type, ...rest}) {
    switch (type) {
        case "set":
            return { ...state, ...rest};
        default:
            return state;
    }
}
 */

const initialState = {
    sideBarShow: false,
};

const sideBarShowReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SIDEBAR_SHOW":
            return { ...state, sideBarShow: true };
        case "SIDEBAR_HIDE":
            return { ...state, sideBarShow: false };
        default:
            return state;
    }
};

export default sideBarShowReducer;