import {
  LOGIN_USER_REQUEST,
  SAVE_LOGIN_USER_DATA,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  SELECTED_USER_DATA,
  ACTIVE_USER_REQUEST,
  ACTIVE_USER_SUCCESS,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  LIST_OF_CHAT_REQUEST,
} from "../type/action-type";

export const getLoginUserRequest = (credential, navigate) => ({
  type: LOGIN_USER_REQUEST,
  credential,
  navigate,
});

export const saveLoginUserData = (data) => ({
  type: SAVE_LOGIN_USER_DATA,
  data,
});

export const forgotPasswordRequest = (credential, navigate) => ({
  type: FORGOT_PASSWORD_REQUEST,
  credential,
  navigate,
});

export const saveForgotPasswordSuccess = (data) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  data,
});

export const resetPasswordRequest = (credential, navigate) => ({
  type: RESET_PASSWORD_REQUEST,
  credential,
  navigate,
});

export const saveResetPasswordSuccess = (data) => ({
  type: RESET_PASSWORD_SUCCESS,
  data,
});

export const getUserListRequest = (currentPage, searchKeyword) => ({
  type: USER_LIST_REQUEST,
  currentPage,
  searchKeyword,
});

export const getUserListSuccess = (data) => ({
  type: USER_LIST_SUCCESS,
  data,
});

export const postUserRequest = (data) => ({
  type: CREATE_USER_REQUEST,
  data,
});

export const postUserSuccess = (data) => ({
  type: CREATE_USER_SUCCESS,
  data,
});

export const postUserEditRequest = (data) => ({
  type: EDIT_USER_REQUEST,
  data,
});

export const postUserEditSuccess = (data) => ({
  type: EDIT_USER_SUCCESS,
  data,
});

export const deleteUserRequest = (id) => ({
  type: DELETE_USER_REQUEST,
  id,
});

export const deleteUserSuccess = (id) => ({
  type: DELETE_USER_SUCCESS,
  id,
});

export const selectedUser = (data) => ({
  type: SELECTED_USER_DATA,
  data,
});

export const activeUserRequest = (data) => ({
  type: ACTIVE_USER_REQUEST,
  data,
});

export const activeUserSuccess = (data) => ({
  type: ACTIVE_USER_SUCCESS,
  data,
});

export const changePasswordRequest = (data, navigate) => ({
  type: CHANGE_PASSWORD_REQUEST,
  data,
  navigate,
});

export const changePasswordSuccess = (data) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  data,
});

export const getChatsRequest = (room) => ({
  type: LIST_OF_CHAT_REQUEST,
  room,
});
