import axios from "axios";
import { apiDomain } from "../utils/constant";

//const localUrl = "http://localhost:8081"
let domain = `${apiDomain}/api/admin`;
// let domain = "http://localhost:8081/api/admin";

const getHeaders = () => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const goldenChatsApi = {
  apis: {
    auth: {
      login: (credential) =>
        axios.post(`${domain}/v1/signUp/login`, credential),
      forgotPassword: (credential) =>
        axios.post(`${domain}/v1/signUp/forgotPassword`, credential),
      resetPassword: (credential) =>
        axios.post(`${domain}/v1/signUp/resetPassword`, credential),
    },
    user: {
      getAllUsers: (params) =>
        axios.get(
          `${domain}/v1/signUp/getAllList?pageLength=4&page=${params?.page}&searchKey=${params.searchKey}`,
          getHeaders()
        ),
      postUser: (data) =>
        axios.post(`${domain}/v1/signUp/createUser`, data, getHeaders()),
      editUser: (data) =>
        axios.put(`${domain}/v1/signUp/updateUser`, data, getHeaders()),
      deleteUser: (id) =>
        axios.delete(`${domain}/v1/signUp/deleteUser/${id}`, getHeaders()),
      activeUser: (id, data) =>
        axios.put(`${domain}/v1/signUp/updateStatus/${id}`, data, getHeaders()),
    },
    chat: {
      getChats: (params) => {
        axios.get(
          `${domain}/v1/chat/getChatMsgs?chatRoomId=${params}`,
          getHeaders()
        );
      },
    },
  },
};

export default goldenChatsApi;
