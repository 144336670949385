import { combineReducers } from "redux"
import adminUserReducer from "src/reducer/adminUser"
import sideBarShowReducer from "src/reducer/sideBarShow"

const RootReducer = combineReducers({
    sideBarShow:sideBarShowReducer,
    user: adminUserReducer
})

export default RootReducer
