import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import AppContent from "./components/AppContent";
import "./scss/style.scss";
import { Toaster } from "react-hot-toast";
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <AppContent />
        <Toaster position="top-center" />
      </BrowserRouter>
    );
  }
}

export default App;
