import * as type from "../type/action-type";

const initialState = {
  users: [],
  addedUser: [],
  editUser: [],
  loading: false,
  error: null,
  deleteUsers: [],
  selectedUser: null,
};

function adminUserReducer(state = initialState, action) {
  switch (action.type) {
    case type.SELECTED_USER_DATA:
      const updatedData = {
        ...action.data,
        password: ""
      };
      return {
        ...state,
        loading: false,
        selectedUser: updatedData,
      };
    case type.EMPTY_SELECTED_USER:
      return {
        ...state,
        loading: false,
        selectedUser: null,
      };
    case type.USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.data,
      };
    case type.CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        addedUser: action.data,
      };
    case type.EDIT_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.EDIT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        editUser: action.data,
      };
    case type.DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteUsers: action?.data,
      };
    default:
      return state;
  }
}

export default adminUserReducer;