import { lazy } from "react";

const Dashboard = lazy(() => import("../views/dashboard/Dashboard"));
const User = lazy(() => import("../views/users/Users"));
const Colors = lazy(() => import("../views/theme/colors/Colors"));
const Typography = lazy(() =>
  import("../views/theme/typography/Typography")
);

// Base
const Accordion = lazy(() => import("../views/base/accordion/Accordion"));
const Breadcrumbs = lazy(() =>
  import("../views/base/breadcrumbs/Breadcrumbs")
);
const Cards = lazy(() => import("../views/base/cards/Cards"));
const Carousels = lazy(() => import("../views/base/carousels/Carousels"));
const Collapses = lazy(() => import("../views/base/collapses/Collapses"));
const ListGroups = lazy(() =>
  import("../views/base/list-groups/ListGroups")
);
const Navs = lazy(() => import("../views/base/navs/Navs"));
const Paginations = lazy(() =>
  import("../views/base/paginations/Paginations")
);
const Placeholders = lazy(() =>
  import("../views/base/placeholders/Placeholders")
);
const Popovers = lazy(() => import("../views/base/popovers/Popovers"));
const Progress = lazy(() => import("../views/base/progress/Progress"));
const Spinners = lazy(() => import("../views/base/spinners/Spinners"));
const Tables = lazy(() => import("../views/base/tables/Tables"));
const Tooltips = lazy(() => import("../views/base/tooltips/Tooltips"));

// Buttons
const Buttons = lazy(() => import("../views/buttons/buttons/Buttons"));
const ButtonGroups = lazy(() =>
  import("../views/buttons/button-groups/ButtonGroups")
);
const Dropdowns = lazy(() =>
  import("../views/buttons/dropdowns/Dropdowns")
);

//Forms
const ChecksRadios = lazy(() =>
  import("../views/forms/checks-radios/ChecksRadios")
);
const FloatingLabels = lazy(() =>
  import("../views/forms/floating-labels/FloatingLabels")
);
const FormControl = lazy(() =>
  import("../views/forms/form-control/FormControl")
);
const InputGroup = lazy(() =>
  import("../views/forms/input-group/InputGroup")
);
const Layout = lazy(() => import("../views/forms/layout/Layout"));
const Range = lazy(() => import("../views/forms/range/Range"));
const Select = lazy(() => import("../views/forms/select/Select"));
const Validation = lazy(() =>
  import("../views/forms/validation/Validation")
);

const Charts = lazy(() => import("../views/charts/Charts"));

// Icons
const CoreUIIcons = lazy(() =>
  import("../views/icons/coreui-icons/CoreUIIcons")
);
const Flags = lazy(() => import("../views/icons/flags/Flags"));
const Brands = lazy(() => import("../views/icons/brands/Brands"));

// Notifications
const Alerts = lazy(() => import("../views/notifications/alerts/Alerts"));
const Badges = lazy(() => import("../views/notifications/badges/Badges"));
const Modals = lazy(() => import("../views/notifications/modals/Modals"));
const Toasts = lazy(() => import("../views/notifications/toasts/Toasts"));

const Widgets = lazy(() => import("../views/widgets/Widgets"));

//Web Chat page
const Chat = lazy(() => import("../webChat/components/Chat/Chat"))
const Join = lazy(() => import("../webChat/components/Join/Join"))

// Containers
// const DefaultLayout = lazy(() => import('../layout/DefaultLayout'))

// Pages
const Login = lazy(() => import("../views/pages/login/Login"));
const Register = lazy(() => import("../views/pages/register/Register"));
const Page404 = lazy(() => import("../views/pages/page404/Page404"));
const Page500 = lazy(() => import("../views/pages/page500/Page500"));
const ForgotPassword = lazy(() =>
  import("../views/pages/forgotPassword/ForgotPassword")
);
const ChangePassword = lazy(() => import("../views/pages/changePassword/ChangePassword"))
const ResetPassword = lazy(() =>
  import("../views/pages/resetPassword/ResetPassword")
);

const routes = [
  {
    path: "/login",
    exact: true,
    name: "LoginPage",
    element: Login,
    authenticated: false,
  },
  {
    path: "/forgot-password",
    exact: true,
    name: "ForgotPasswordPage",
    element: ForgotPassword,
    authenticated: false,
  },
  {
    path: "/reset-password/:token",
    exact: true,
    name: "ResetPasswordPage",
    element: ResetPassword,
    authenticated: false,
  },
  {
    path: "/register",
    exact: true,
    name: "RegisterPage",
    element: Register,
    authenticated: false,
  },
  {
    path: "*",
    exact: true,
    name: "Page404",
    element: Page404,
    role: ["ADMIN", "USER"],
    authenticated: false,
  },
  {
    path: "/500",
    exact: true,
    name: "Page500",
    element: Page500,
    authenticated: false,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    element: Dashboard,
    authenticated: true,
    role: ["ADMIN", "USER"],
  },
  {
    path: "/change-password",
    exact: true,
    name: "ChangePasswordPage",
    element: ChangePassword,
    authenticated: true,
  },
  {
    path: "/user",
    name: "User",
    element: User,
    authenticated: true,
    role: ["ADMIN", "USER"],
  },
  {
    path: "/theme",
    name: "Theme",
    element: Colors,
    exact: true,
    authenticated: true,
    role: ["ADMIN", "USER"],
  },
  {
    path: "/theme/colors",
    name: "Colors",
    element: Colors,
    authenticated: true,
    role: ["ADMIN", "USER"],
  },
  {
    path: "/theme/typography",
    name: "Typography",
    element: Typography,
    authenticated: true,
  },
  {
    path: "/based",
    name: "Base",
    element: Cards,
    exact: true,
    authenticated: true,
  },
  {
    path: "/base/accordion",
    name: "Accordion",
    element: Accordion,
    authenticated: true,
  },
  {
    path: "/base/breadcrumbs",
    name: "Breadcrumbs",
    element: Breadcrumbs,
    authenticated: true,
  },
  { path: "/base/cards", name: "Cards", element: Cards, authenticated: true },
  {
    path: "/base/carousels",
    name: "Carousel",
    element: Carousels,
    authenticated: true,
  },
  {
    path: "/base/collapses",
    name: "Collapse",
    element: Collapses,
    authenticated: true,
  },
  {
    path: "/base/list-groups",
    name: "List Groups",
    element: ListGroups,
    authenticated: true,
  },
/*   { path: "/base/navs", name: "Navs", element: Navs, authenticated: true },
 */  {
    path: "/base/paginations",
    name: "Paginations",
    element: Paginations,
    authenticated: true,
  },
  {
    path: "/base/placeholders",
    name: "Placeholders",
    element: Placeholders,
    authenticated: true,
  },
  {
    path: "/base/popovers",
    name: "Popovers",
    element: Popovers,
    authenticated: true,
  },
  {
    path: "/base/progress",
    name: "Progress",
    element: Progress,
    authenticated: true,
  },
  {
    path: "/base/spinners",
    name: "Spinners",
    element: Spinners,
    authenticated: true,
  },
  {
    path: "/base/tables",
    name: "Tables",
    element: Tables,
    authenticated: true,
  },
  {
    path: "/base/tooltips",
    name: "Tooltips",
    element: Tooltips,
    authenticated: true,
  },
  {
    path: "/buttons",
    name: "Buttons",
    element: Buttons,
    exact: true,
    authenticated: true,
  },
  {
    path: "/buttons/buttons",
    name: "Buttons",
    element: Buttons,
    authenticated: true,
  },
  {
    path: "/buttons/dropdowns",
    name: "Dropdowns",
    element: Dropdowns,
    authenticated: true,
  },
  {
    path: "/buttons/button-groups",
    name: "Button Groups",
    element: ButtonGroups,
    authenticated: true,
  },
  { path: "/charts", name: "Charts", element: Charts, authenticated: true },
  {
    path: "/forms",
    name: "Forms",
    element: FormControl,
    exact: true,
    authenticated: true,
  },
  {
    path: "/forms/form-control",
    name: "Form Control",
    element: FormControl,
    authenticated: true,
  },
  {
    path: "/forms/select",
    name: "Select",
    element: Select,
    authenticated: true,
  },
  {
    path: "/forms/checks-radios",
    name: "Checks & Radios",
    element: ChecksRadios,
    authenticated: true,
  },
  { path: "/forms/range", name: "Range", element: Range, authenticated: true },
  {
    path: "/forms/input-group",
    name: "Input Group",
    element: InputGroup,
    authenticated: true,
  },
  {
    path: "/forms/floating-labels",
    name: "Floating Labels",
    element: FloatingLabels,
    authenticated: true,
  },
  {
    path: "/forms/layout",
    name: "Layout",
    element: Layout,
    authenticated: true,
  },
  {
    path: "/forms/validation",
    name: "Validation",
    element: Validation,
    authenticated: true,
  },
  {
    path: "/icons",
    exact: true,
    name: "Icons",
    element: CoreUIIcons,
    authenticated: true,
  },
  {
    path: "/icons/coreui-icons",
    name: "CoreUI Icons",
    element: CoreUIIcons,
    authenticated: true,
  },
  { path: "/icons/flags", name: "Flags", element: Flags, authenticated: true },
  {
    path: "/icons/brands",
    name: "Brands",
    element: Brands,
    authenticated: true,
  },
  {
    path: "/notifications",
    name: "Notifications",
    element: Alerts,
    exact: true,
    authenticated: true,
  },
  {
    path: "/notifications/alerts",
    name: "Alerts",
    element: Alerts,
    authenticated: true,
  },
  {
    path: "/notifications/badges",
    name: "Badges",
    element: Badges,
    authenticated: true,
  },
  {
    path: "/notifications/modals",
    name: "Modals",
    element: Modals,
    authenticated: true,
  },
  {
    path: "/notifications/toasts",
    name: "Toasts",
    element: Toasts,
    authenticated: true,
  },
  { path: "/widgets", name: "Widgets", element: Widgets, authenticated: true },
  { path: "/chat", name: "chat", element: Chat, authenticated: false},
  { path: "/join", name: "join", element: Join, authenticated: false}
];

export default routes;
