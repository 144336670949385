
import {
  ACTIVE_USER_REQUEST,
  ACTIVE_USER_SUCCESS,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN_USER_REQUEST,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  SAVE_LOGIN_USER_DATA,
  SELECTED_USER_DATA,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS
} from "../type/action-type";
import { call, put } from "redux-saga/effects";
import { takeLatest } from 'redux-saga/effects'
import _get from "lodash/get";
import { saveLoginUserData, saveForgotPasswordSuccess, saveResetPasswordSuccess, getUserListSuccess, postUserSuccess, postUserEditSuccess, deleteUserSuccess, activeUserSuccess, changePasswordSuccess } from "../actions/adminUser";
import goldenChatsApi from 'src/api';
import toast from "react-hot-toast";
function* loginUser(loginUserAction) {
  try {
    const credential = _get(loginUserAction, "credential", {});
    const response = yield call(goldenChatsApi.apis.auth.login, credential);
    localStorage.setItem("token", _get(response.data, "data"));
    if (response.data.status === 404) {
      toast.error("Please verify your credentials");
    }
    else {
      loginUserAction.navigate("/dashboard");
      toast.success("Login Successfully");
      yield put(saveLoginUserData(_get(response, "data", {})));
    }
  } catch (error) {
    toast.error("Invalid Credential")
    // toast.error(_get(error, "response.data.message ", _get(error, "message", "")))
  }
}

function* forgotPassword(forgotPasswordData) {
  try {
    const credential = _get(forgotPasswordData, "credential", {});
    const response = yield call(goldenChatsApi.apis.auth.forgotPassword, credential);
    forgotPasswordData.navigate("/login");
    toast.success("Reset Password Link Sent Successfully");
    yield put(saveForgotPasswordSuccess(_get(response, "data", {})));
  } catch (error) {
    console.error("error in forgot password ::", error);
    toast.error(_get(error, "response.data.message", _get(error, "message", "")))
  }
}

function* resetPassword(resetPasswordData) {
  try {
    const credential = _get(resetPasswordData, "credential", {});
    const response = yield call(goldenChatsApi.apis.auth.resetPassword, credential);
    resetPasswordData.navigate("/login");
    toast.success("Password Reset Successfully");
    yield put(saveResetPasswordSuccess(_get(response, "data", {})));
  } catch (error) {
    console.error("error in reset password ::", error);
    toast.error(_get(error, "response.data.message", _get(error, "message", "")))
  }
}

function* changePassword(changePasswordData) {
  try {
    const id = _get(changePasswordData.data, "id", {});
    const pwd = {
      password: _get(changePasswordData.data, "password", {})
    }
    const response = yield call(goldenChatsApi.apis.auth.changePassword, id, pwd);
    changePasswordData.navigate("/login");
    toast.success("Password has been changed successfully");
    yield put(saveResetPasswordSuccess(_get(response, "data", {})));
  } catch (error) {
    console.error("error in reset password ::", error);
    toast.error(_get(error, "response.data.message", _get(error, "message", "")))
  }
}

function* userList(getUserListdata) {
  try {
    const page = _get(getUserListdata, "currentPage", {});
    const searchKey = _get(getUserListdata, "searchKeyword", {});
    var params = {
      page,
      searchKey
    }
    const response = yield call(goldenChatsApi.apis.user.getAllUsers, params);
    yield put(getUserListSuccess(_get(response, "data", {})));
  } catch (error) {
    console.error("error in getting list ::", error);
    toast.error(_get(error, "response.data.message", _get(error, "message", "")))
  }
}

function* createUser(data) {
  try {
    const userdata = _get(data, "data", {});
    const response = yield call(goldenChatsApi.apis.user.postUser, userdata);
    if (response.data.status === 400) {
      toast.error(_get(response, "data.message", _get(response, "message", "")))
    } else {
      toast.success("User added succesfully");
      yield put(postUserSuccess(_get(response, "data", {})));
    }
  } catch (error) {
    console.error("error in adding user ::", error);
    toast.error(_get(error, "response.data.message", _get(error, "message", "")))
  }
}

function* editUser(data) {
  try {
    const userdata = _get(data, "data", {});
    const response = yield call(goldenChatsApi.apis.user.editUser, userdata);
    if (response.data.status === 400) {
      toast.error(_get(response, "data.message", _get(response, "message", "")))
    } else {
      toast.success("User updated succesfully");
      yield put(postUserEditSuccess(_get(response, "data.data", {})));
    }
  } catch (error) {
    console.error("error in adding user ::", error);
    toast.error(_get(error, "response.data.message", _get(error, "message", "")))
  }
}

function* deleteUserData(id) {
  try {
    const userId = _get(id, "id", {});
    const response = yield call(goldenChatsApi.apis.user.deleteUser, userId);
    if (response.data.status === 400) {
      toast.error(_get(response, "data.message", _get(response, "message", "")))
    } else {
      toast.success("User deleted succesfully");
      yield put(deleteUserSuccess(_get(response, "data.data", {})));
    }
  } catch (error) {
    console.error("error in adding user ::", error);
    toast.error(_get(error, "response.data.message", _get(error, "message", "")))
  }
}

function* activeUserData(data) {
  try {
    const userId = _get(data.data, "UserId", {});
    const active = {
      isActive: _get(data.data, "IsActive", {})
    }
    const response = yield call(goldenChatsApi.apis.user.activeUser, userId, active);
    if (response.data.status === 400) {
      toast.error(_get(response, "data.message", _get(response, "message", "")))
    } else {
      if (response?.data?.data?.isActive === false) {
        toast.success("User deactive succesfully");
      } else {
        toast.success("User active succesfully");
      }
      yield put(deleteUserSuccess(_get(response, "data.data", {})));
    }
  } catch (error) {
    console.error("error in adding user ::", error);
    toast.error(_get(error, "response.data.message", _get(error, "message", "")))
  }
}

export default function* adminUserSaga() {
  yield takeLatest(LOGIN_USER_REQUEST, loginUser);
  yield takeLatest(SAVE_LOGIN_USER_DATA, saveLoginUserData);
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeLatest(FORGOT_PASSWORD_SUCCESS, saveForgotPasswordSuccess);
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword)
  yield takeLatest(RESET_PASSWORD_SUCCESS, saveResetPasswordSuccess)
  yield takeLatest(USER_LIST_REQUEST, userList)
  yield takeLatest(USER_LIST_SUCCESS, getUserListSuccess)
  yield takeLatest(CREATE_USER_REQUEST, createUser)
  yield takeLatest(CREATE_USER_SUCCESS, postUserSuccess)
  yield takeLatest(EDIT_USER_REQUEST, editUser)
  yield takeLatest(EDIT_USER_SUCCESS, postUserEditSuccess)
  yield takeLatest(DELETE_USER_REQUEST, deleteUserData)
  yield takeLatest(DELETE_USER_SUCCESS, deleteUserSuccess)
  yield takeLatest(ACTIVE_USER_REQUEST, activeUserData)
  yield takeLatest(ACTIVE_USER_SUCCESS, activeUserSuccess)
  yield takeLatest(CHANGE_PASSWORD_REQUEST, changePassword)
  yield takeLatest(CHANGE_PASSWORD_SUCCESS, changePasswordSuccess)
}
