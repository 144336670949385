export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const SAVE_LOGIN_USER_DATA = "SAVE_LOGIN_USER_DATA";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const SELECTED_USER_DATA = "SELECTED_USER_DATA";
export const EMPTY_SELECTED_USER = "EMPTY_SELECTED_USER";
export const ACTIVE_USER_REQUEST = "ACTIVE_USER_REQUEST";
export const ACTIVE_USER_SUCCESS = "ACTIVE_USER_SUCCESS";

export const LIST_OF_CHAT_REQUEST = "LIST_OF_CHAT_REQUEST";
